.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.oswald {font-family: 'Oswald', sans-serif;}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: grey !important;
}
.MuiInputLabel-root {
  color: white !important;
}
.MuiTabs-flexContainer {


background: #EB9486;
height: 50px;

}
.MuiInput-underline{

  font-family: 'Oswald', sans-serif;
  color: #fff !important;
  border-bottom: 2px solid #eb9486 !Important;
}